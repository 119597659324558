<template>
  <div class="index">
    <div class="banxin">
      <h5>{{ detail.name }}</h5>
      <div class="top">
        <div class="left">
          &nbsp;&nbsp;阅读 {{ detail.viewNum }}
        </div>
        <div class="right">
          {{ detail.createdAt }}
        </div>
      </div>
      <div style="margin-top: 8px; width: 100%; font-size: 14px; line-height: 18px" v-html="detail.content">
      </div>
    </div>
  </div>
</template>
<script>
  import { circleDetail } from "@/api/morningPaper";
  import { activityDetail, shareCallback, openUserInfoApi } from '@/api/electronicCard'
  import { wxConfig } from "@/utils/wxCodeAuth";
  export default {
    data() {
      return {
        id: '',
        employeeId: '',
        detail: '',
        unionid: '',
      }
    },
    created() {
      this.id = this.$route.query.id
      this.employeeId = this.$route.query.employeeId
      this.getOpenUserInfo();
    },
    methods: {
      getCircleDetail() {
        circleDetail({ employeeId: this.employeeId, id: this.id, unionId: this.unionid }).then(res => {
          //如果contentType当为2时，跳转返回的url
          if (res.data.contentType == 2) {
            window.location.href = res.data.url
            return
          }
          this.detail = res.data
          let that = this;
          //分享内容
          this.shareOption = {
            title: this.detail.name, // 分享标题
            desc: '', // 分享描述
            link: this.detail.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function (row) {
              // 用户点击了分享后执行的回调函数
              let params = {
                employeeId: that.employeeId,
                unionId: that.unionid,
                type: '8',
                targetId: that.id
              }
              shareCallback(params)
            },
          };
          let shareFunction = function () {
            //需在用户可能点击分享按钮前就先调用
            wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
            wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
            wx.onMenuShareQQ(that.shareOption);//分享到qq
            wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
          };
          //加载微信配置
          wxConfig(shareFunction);
        })
      },
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          employeeId: this.employeeId,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/vcard?employeeId=" +
              that.employeeId +
              "&target=" +
              encodeURIComponent(
                "/electronicCard/circleDetail" + location.search
              );
            window.location.href = redirectUrl;
            return;
          }
          this.unionid = res.data.unionid;
          this.getCircleDetail();
        });
      },
    }
  }
</script>
<style lang="less" scoped>
  .banxin {
    width: 94%;
    margin: 0 auto;
  }

  h5 {
    margin: 16px 10px 10px 10px;
    min-height: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #000000;
    line-height: 24px;
    text-align: center;
  }

  .top {
    display: flex;
    justify-content: space-between;
    height: 19px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #717174;
    line-height: 19px;
  }

  .left {
    width: 56%;
  }

  /deep/ img {
    max-width: 100% !important;
  }
  /deep/ .ql-align-center{
    text-align: center;
  }
</style>