<template>
  <div class="index">
    <div>
      <div class="allUserInfo">
        <div class="userInfo">
          <div class="content" v-if="employeeData != ''">
            <div class="contentLeft">
              <img class="avatar" :src="employeeData.fullImage" alt="" />
              <div class="contentBot">
                <div class="round" @click="daPhone">
                  <img class="phone" :src="phoneiconImg" alt="" />
                </div>
                <div class="round" @click="showWechat = true">
                  <img class="phone" :src="weChatImg" alt="" style="width: 16px; height: 13px" />
                </div>
              </div>
            </div>
            <div class="contentRight">
              <img class="logo" :src="logoUrl" alt="" />
              <div>
                <div class="name">
                  <div class="name2">
                    <span class="span" v-if="employeeData.name.split('').length < 6">{{ employeeData.name }}</span>
                    <span class="span2 marquee-wrap" v-else>{{
                      employeeData.name
                      }}</span>
                  </div>
                  <!-- <img
                    src="@/assets/index/bianji.png"
                    class="nameImg"
                    @click="editInfo"
                    alt=""
                  /> -->
                </div>
                <div class="position">
                  {{ employeeData.departmentName }}&nbsp;
                  {{ employeeData.position }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else class="infoNull">
            <div class="infoTitle">您好！请先编辑个人资料～</div>
            <div class="infoBtn" @click="editInfo">去编辑</div>
          </div> -->
        </div>
        <div class="detail" :style="{ height: employeeData == '' ? '50px' : '' }">
          <div class="expand" v-if="showDetail == false" @click="showDetail = !showDetail">
            <span>展开全部信息</span> &emsp;<img src="../../assets/index/xia.png" alt="" />
          </div>
          <div class="expand" v-else @click="showDetail = !showDetail">
            <span>收起</span> &emsp;<img src="../../assets/index/shang.png" alt="" />
          </div>

          <div v-if="showDetail == true" class="detailbg">
            <div class="intrudction">
              个人简介：{{ employeeData.introduction }}
            </div>
            <div class="intrudction">
              资质证书：<span v-for="(cs, j) in employeeData.certificates" :key="j">{{ cs }}&emsp;</span>
            </div>
            <div class="intrudction">
              服务格言：{{ employeeData.serviceMotto }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 头条 -->
    <div v-if="noticeData.length > 0">
      <div class="toutiao" @click="skipToutiao">
        <img src="../../assets/index/toutiao.png" alt="" />
        <span>{{ msg.title }}</span>
      </div>
    </div>
    <div class="toutiao" v-else>
      <img src="../../assets/index/toutiao.png" alt="" />
      <span>暂无通知！</span>
    </div>
    <!-- <div class="box">
        <div class="left" @click="skipCaijing">
          <img src="../../assets/images/electronicCard/pic1.png" alt="" />
          <div class="subcontent">
            <div class="title">财经资讯</div>
            <div class="subtitle">天天更新</div>
          </div>
        </div>
        <div class="right" @click="skipActivity">
          <img src="../../assets/images/electronicCard/pic2.png" alt="" />
          <div class="subcontent">
            <div class="title">热门活动</div>
            <div class="subtitle">快来参与吧</div>
          </div>
        </div>
      </div> -->
    <!-- 早报晚报 -->
    <div class="m20">
      <div class="left">今日头条</div>
      <div class="right"></div>
    </div>
    <div class="mornEven">
      <div class="morning" @click="skipMorning">
        <div style="width: 94%; margin: 0 auto">
          <div class="morning_top">
            {{ paperData.morning.created_at }} {{ paperData.morning.week }}
          </div>
          <div class="morning_title"></div>
          <div class="shareTimes">
            今日分享{{ paperData.morning.share_num }}次
          </div>
        </div>
      </div>
      <div class="morning evening" @click="skipEvening">
        <div style="width: 94%; margin: 0 auto">
          <div class="morning_top">
            {{ paperData.night.created_at }} {{ paperData.night.week }}
          </div>
          <div class="morning_title"></div>
          <div class="shareTimes">
            今日分享{{ paperData.night.share_num }}次
          </div>
        </div>
      </div>
    </div>
    <!-- 每日专题 -->
    <div class="topic">
      <div class="topicTop">
        <div class="left">每日专题</div>
        <div class="right" @click="skipMoreTopic">查看更多 ></div>
      </div>
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item v-for="(res, i) in topicData" :key="i">
          <div class="topicMain" @click="skipTopicDetail(res.id)">
            <div class="left">
              <div class="title">
                {{ res.name }}
              </div>
              <div class="tag">
                <span v-for="(tag, index) in res.tags" :key="index">{{
                  tag
                  }}</span>
              </div>
              <div class="shareData">
                {{ res.shareNum }}人已分享，{{ res.viewNum }}人已阅读
              </div>
            </div>
            <div class="right">
              <img :src="res.fullCover" alt="" />
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 热销产品专区 -->
    <div class="prefecture" v-show="salesProductList.length > 0">
      <div class="pic">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="blue">
          <van-swipe-item v-for="(item, index) in salesProductList" :key="index"><img class="prefectureImg"
              :src="item.fullImage" alt="" />
            <div class="prefectureName">{{ item.name }}</div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 精选活动 -->
    <div class="selectAct">
      <div class="actTop">
        <div class="left">精选活动</div>
        <div class="right" @click="skipMoreAct">查看更多 ></div>
      </div>
      <div>
        <img @click="skipActDetail" :src="activityData.fullImage" alt="" />
      </div>
    </div>
    <!-- 金融产品分类 -->
    <div class="m20">
      <div class="left">热推产品</div>
      <div class="right"></div>
    </div>
    <!-- 热推产品 -->
    <div class="product">
      <div class="product_tab" v-if="options.length != 0">
        <div class="product_list" v-for="(item, index) in options" :key="index">
          <div class="product_name" :class="{ active: index == choose }" @click="selectTab(item, index)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-if="productData.length != 0">
        <div class="product_con" v-for="(item, index) in productData" :key="index" @click="skipProductDetail(item.id)">
          <div class="product_con_left">
            <div class="title">{{ item.name }}</div>
            <div class="content">{{ item.summary }}</div>
          </div>
          <div class="product_con_right">
            <img :src="item.fullImage" alt="" />
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty description="暂无数据" />
      </div>
    </div>
    <!-- 聚合接口文章 -->
    <div v-if="categoryInformationList.length > 0">
      <div v-for="(item, index) in categoryInformationList" :key="index">
        <div class="m20">
          <div class="left">{{ item.categoryName }}</div>
          <div class="right"></div>
        </div>
        <div class="juhe" v-for="(res, i) in item.list" :key="i">
          <div class="juhe_top">
            <span class="numbg"></span>
            <i>0{{ i + 1 }}</i>
          </div>
          <div class="aside">
            <div :class="[res.thumbnail_pic != '' ? 'title' : 'title2']">
              {{ res.title }}
            </div>
            <img v-show="res.thumbnail_pic != ''" :src="res.thumbnail_pic" alt="" style="width: 80px; height: 46px" />
          </div>
          <div class="main" @click="skipToJuheDetail(res.id)">
            {{ res.summary }}
          </div>
          <div class="bott">{{ res.author_name }}</div>
        </div>
      </div>
    </div>
    <div class="m20" style="margin-top: -10px">
      <div class="left">今日汽油</div>
      <div class="right"></div>
    </div>
    <div class="bottom">
      <div class="con">
        <div class="a12">0号汽油</div>
        <div class="a13">{{ oilData["0h"] }}</div>
        <div class="a14">元/升</div>
      </div>
      <div class="con">
        <div class="a12">92号汽油</div>
        <div class="a13">{{ oilData["92h"] }}</div>
        <div class="a14">元/升</div>
      </div>
      <div class="con">
        <div class="a12">95号汽油</div>
        <div class="a13">{{ oilData["95h"] }}</div>
        <div class="a14">元/升</div>
      </div>
      <div class="con">
        <div class="a12">98号汽油</div>
        <div class="a13">{{ oilData["98h"] }}</div>
        <div class="a14">元/升</div>
      </div>
    </div>
    <div class="m20">
      <div class="left">大盘指数</div>
      <div class="right"></div>
    </div>
    <div class="asideTop" style="margin-top: 6px">
      <div :class="
          stockData.szzz_index.increPer.indexOf('-') == -1 ? 'Box' : 'BoxGreen'
        ">
        <div class="gray">上证指数</div>
        <div :class="
            stockData.szzz_index.increPer.indexOf('-') == -1 ? 'm16' : 'm18'
          ">
          {{ stockData.szzz_index.nowpri }}
        </div>
        <div style="height: 18px; line-height: 18px">
          <span :class="
              stockData.szzz_index.increPer.indexOf('-') == -1 ? 'm17' : 'm19'
            ">{{ stockData.szzz_index.increPer }}</span>
          <span :class="
              stockData.szzz_index.increase.indexOf('-') == -1 ? 'm17' : 'm19'
            ">
            {{ stockData.szzz_index.increase }}</span>
        </div>
      </div>
      <div :class="
          stockData.szcz_index.increPer.indexOf('-') == -1 ? 'Box' : 'BoxGreen'
        ">
        <div class="gray">深证成指</div>
        <div :class="
            stockData.szcz_index.increPer.indexOf('-') == -1 ? 'm16' : 'm18'
          ">
          {{ stockData.szcz_index.nowpri }}
        </div>
        <div style="height: 18px; line-height: 18px">
          <span :class="
              stockData.szcz_index.increPer.indexOf('-') == -1 ? 'm17' : 'm19'
            ">{{ stockData.szcz_index.increPer }}</span>
          <span :class="
              stockData.szcz_index.increase.indexOf('-') == -1 ? 'm17' : 'm19'
            ">
            {{ stockData.szcz_index.increase }}</span>
        </div>
      </div>
      <div :class="
          stockData.hszs_index.increPer.indexOf('-') == -1 ? 'Box' : 'BoxGreen'
        ">
        <div class="gray">恒生指数</div>
        <div :class="
            stockData.hszs_index.increPer.indexOf('-') == -1 ? 'm16' : 'm18'
          ">
          {{ stockData.hszs_index.nowpri }}
        </div>
        <div style="height: 18px; line-height: 18px">
          <span :class="
              stockData.hszs_index.increPer.indexOf('-') == -1 ? 'm17' : 'm19'
            ">{{ stockData.hszs_index.increPer }}</span>
          <span :class="
              stockData.hszs_index.increase.indexOf('-') == -1 ? 'm17' : 'm19'
            ">
            {{ stockData.hszs_index.increase }}</span>
        </div>
      </div>
    </div>

    <!-- 日思录 -->
    <div class="m20">
      <div class="left">日思录</div>
      <div class="right"></div>
    </div>
    <div class="risilu">
      <div class="risilu_content">
        <div class="title">{{ words.name }}</div>
        <div class="con">
          {{ words.comment }}
        </div>
      </div>
    </div>
    <!-- 版权声明 -->
    <div class="copyright">
      <div class="box_title">免责声明：</div>
      <div class="box_cen">
        以上内容仅供参考，引用之目的在于共享资讯，不代表对信息来源内容的真实性、准确性负责。以上内容不构成对任何人的投资建议，敬请您独立决策并承担风险。如信息内容侵犯知识产权，请提交相关链接至邮箱：service@qsjykj.com.cn，我们将及时处理。市场有风险，投资须谨慎
      </div>
    </div>
    <div style="height: 82px"></div>
    <!-- <div class="last">
        <button class="btn1" v-if="shareUrl != ''" @click="editInfo">
          编辑个人信息
        </button>
        <button class="btn1" style="margin: 0 auto" v-else @click="editInfo">
          编辑个人信息
        </button>
        <button class="btn2" @click="share" v-if="shareUrl != ''">
          分享我的电子名片
        </button>
      </div> -->
    <div class="fixedBox">
      <img v-if="bankUrl != ''" :src="wositeImg" @click="goPage('bank')" style="width: 50px; height: 56px" alt="" />
    </div>
    <van-dialog v-model="show" show-cancel-button @confirm="confirm" @cancel="cancel(shareUrl)" cancelButtonText="复制链接"
      confirmButtonText="群发给客户" confirmButtonColor="#3974C6">
      <div class="close" @click="close">X</div>
      <div class="address">我的电子名片地址：</div>
      <div class="url">{{ shareUrl }}</div>
    </van-dialog>
    <!-- 拨打电话弹窗 -->
    <van-dialog v-model="showPhoneCode" show-cancel-button cancelButtonText="否" confirmButtonText="是"
      confirmButtonColor="#3974C6">
      <div class="phoneT">是否拨打行员电话？</div>
    </van-dialog>
    <!-- 微信二维码弹窗 -->
    <van-popup v-model="showWechat" closeable style="width: 260px; height: 286px; text-align: center">
      <img style="
          margin-top: 30px;
          width: 200px;
          height: 190px;
          text-align: center;
        " :src="qrCode" alt="" />
      <p style="font-size: 16px; color: #515151">长按扫一扫<br />即可联系我</p>
    </van-popup>
  </div>
</template>
<script>
  import { jingRongClassify, productList, openUserInfoApi, shareCallback,vcardIndex } from "@/api/electronicCard";
  import { getbankHomeUrl } from "@/api/wowebsite";
  import { shareToExternalContact } from "../../utils/wxCodeAuth";
  import { wxConfig } from "@/utils/wxCodeAuth";

  export default {
    data() {
      return {
        checkStyle: "",
        bgList: [
          require("../../assets/index/bg.png"),
          require("../../assets/index/yellowBg.png"),
          require("../../assets/index/purpleBg.png"),
          require("../../assets/index/skyblueBg.png"),
          require("../../assets/index/orangeBg.png"),
          require("../../assets/index/cyanBg.png"),
          require("../../assets/index/redBg.png"),
        ],
        topBgList: [
          require("../../assets/index/infoBg.png"),
          require("../../assets/index/yellow.png"),
          require("../../assets/index/purpleTopBg.png"),
          require("../../assets/index/skyblueTopBg.png"),
          require("../../assets/index/orangeTopBg.png"),
          require("../../assets/index/cyanTopBg.png"),
          require("../../assets/index/redTopBg.png"),
        ],
        phoneiconList: [
          require("../../assets/index/phone.png"),
          require("../../assets/index/yellowPhone.png"),
          require("../../assets/index/purplePhone.png"),
          require("../../assets/index/skybluePhone.png"),
          require("../../assets/index/orangePhone.png"),
          require("../../assets/index/cyanPhone.png"),
          require("../../assets/index/redPhone.png"),

        ],
        wechaticonList: [
          require("../../assets/index/wechat.png"),
          require("../../assets/index/yellowWechat.png"),
          require("../../assets/index/purpleWechat.png"),
          require("../../assets/index/skyblueWechat.png"),
          require("../../assets/index/orangeWechat.png"),
          require("../../assets/index/cyanWechat.png"),
          require("../../assets/index/redWechat.png"),
        ],
        wositeList: [require('../../static/wowebsite/wowlogo.png'), require('../../assets/index/yellowWosite.png'), require('../../assets/index/purpleWosite.png'), require('../../assets/index/skyblueWosite.png'), require('../../assets/index/orangeWosite.png'), require('../../assets/index/cyanWosite.png'), require('../../assets/index/redWosite.png')],
        classifyBgList: [
          "#58b2e7",
          "#E1BB7C",
          "#6D76FE",
          "#6A98C9",
          "#E84C43",
          "#CCC895",
          "#FF3920",
        ],
        phoneiconImg: "",
        weChatImg: "",
        wositeImg: '',
        choose: 0,
        categoryInformationList: [],
        // 热销产品列表
        salesProductList: [],
        showDetail: false,
        show: false,
        showWechat: false,
        showPhoneCode: false,
        employeeData: "",
        activityData: "",
        oilData: "",
        stockData: "",
        noticeData: [],
        options: [],
        productData: [],
        classifyId: "",
        paperData: "",
        topicData: [],
        words: "",
        logoUrl: "",
        shareUrl: "",
        msg: "",
        timer: "",
        departmentName: "",
        nickName: "",
        touxiang: "",
        qrCode: "",
        phoneNum: "",
        activeC: 1,
        employeeId: "",
        bankUrl: "",
        shareOption: {},
        classifyBg: "",
        unionId: '',
      };
    },
    created() {
      // window.addEventListener("scroll", this.handleScroll);
      this.employeeId = this.$route.query.id;
      this.getOpenUserInfo();//授权登录
    },
    methods: {
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          employeeId: this.employeeId,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/vcard?employeeId=" +
              that.employeeId +
              "&target=" +
              encodeURIComponent("/vcard/record" + location.search)
            window.location.href = redirectUrl;
            return;
          }
          this.unionId = res.data.unionid;
          this.getClassify();//获取产品分类
          this.getData();//获取电子名片数据
          this.getbankurl(); //获取微官网链接
        });
      },
      //获取微官网链接
      getbankurl() {
        getbankHomeUrl({ employeeId: this.employeeId }).then((res) => {
          if (res.code == 200) {
            this.bankUrl = res.data.url ? res.data.url : "";
          }
        });
      },
      //跳转外部链接
      goPage(type) {
        if (type == "bank" && this.bankUrl != "") {
          location.href = this.bankUrl;
        }
      },
      selectTab(row, index) {
        this.choose = index;
        this.activeC = row.id;
        this.getProductData();
        //把上一次点击的背景颜色去掉
        this.classifyBg.style.background = "#e5e5e5";
        this.$nextTick(() => {
          this.classifyBg = document.querySelector(".active");
          this.classifyBg.style.background =
            this.classifyBgList[this.checkStyle - 1];
        });
      },
      skipToJuheDetail(id) {
        this.$router.push({
          path: "/electronicCard/caijing/detail",
          query: {
            id,
            employeeId: this.employeeId,
          },
        });
      },
      handleChange() {
        this.getProductData();
      },
      //获取产品分类
      getClassify() {
        jingRongClassify({ employeeId: this.employeeId }).then((res) => {
          this.options = res.data;
          this.activeC = res.data[0].id ? res.data[0].id : "";
          this.getProductData();
        });
      },
      getProductData() {
        productList({
          categoryId: this.activeC,
          employeeId: this.employeeId,
        }).then((res) => {
          this.productData = res.data.list;
        });
      },
      daPhone() {
        window.location.href = `tel:${this.phoneNum}`;
      },
      skipMorning() {
        this.$router.push({
          path: "/electronicCard/morningPaper/index",
          query: {
            id: this.employeeId,
          },
        });
      },
      skipEvening() {
        this.$router.push({
          path: "/electronicCard/eveningPaper/index",
          query: {
            id: this.employeeId,
          },
        });
      },
      skipMoreTopic() {
        this.$router.push({
          path: "/electronicCard/specialTopic",
          query: { id: this.employeeId },
        });
      },
      skipTopicDetail(id) {
        this.$router.push({
          path: "/electronicCard/specialTopicDetail",
          query: {
            id,
            employeeId: this.employeeId,
          },
        });
      },
      skipMoreAct() {
        this.$router.push({
          path: "/electronicCard/activity",
          query: {
            id: this.employeeId,
          },
        });
      },
      skipActDetail() {
        // if (this.activityData.url == "") {
          this.$router.push({
            path: "/electronicCard/activity/detail",
            query: {
              id: this.activityData.id,
              employeeId: this.employeeId,
            },
          });
        // } else {
        //   location.href = this.activityData.url;
        // }
      },

      skipProductDetail(id) {
        this.$router.push({
          path: "/electronicCard/financialinfo/detail",
          query: {
            id,
            employeeId: this.employeeId,
          },
        });
      },
      skip(categoryId) {
        this.$router.push({
          path: "/electronicCard/financialinfo",
          query: { categoryId },
        });
      },
      getData() {
        vcardIndex({ employeeId: this.employeeId, unionId: this.unionId }).then((res) => {
          const data = res.data;
          this.employeeData = data.employee ? data.employee : "";
          this.paperData = data.post ? data.post : "";
          this.noticeData = data.noticeList ? data.noticeList : [];
          this.productList = data.productList ? data.productList : [];
          this.topicData = data.subjectList ? data.subjectList : [];
          this.activityData = data.activity ? data.activity : "";
          this.oilData = data.oilPrice;
          this.stockData = data.stockMarketIndex;
          this.logoUrl = data.logo
            ? data.logo
            : process.env.VUE_APP_API_BASE_URL + "/static/yunnan.png";
          this.shareUrl = data.shareUrl;
          this.phoneNum = data.employee.mobile;
          var that = this;
          this.msg = this.noticeData[0];
          this.departmentName = data.employee ? data.employee.departmentName : "";
          this.nickName = data.employee ? data.employee.name : "";
          this.touxiang = data.employee ? data.employee.fullImage : "";
          this.qrCode = data.employee ? data.employee.qrCode : "";
          this.salesProductList = data.salesProductList
            ? data.salesProductList
            : [];
          this.categoryInformationList = data.categoryInformationList
            ? data.categoryInformationList
            : [];
          this.words = data.words;
          let shareUrl = location.href;
          let timestamp = new Date().getTime();

          this.checkStyle = data.style;
          //样式更换
          setTimeout(() => {
            this.phoneiconImg = this.phoneiconList[this.checkStyle - 1];
            this.weChatImg = this.wechaticonList[this.checkStyle - 1];
            this.wositeImg = this.wositeList[this.checkStyle - 1]
            const bodyElement = document.querySelector(".index");
            bodyElement.style.backgroundImage = `url(${this.bgList[this.checkStyle - 1]
              })`;
            const topImg = document.querySelector(".userInfo");
            topImg.style.backgroundImage = `url(${this.topBgList[this.checkStyle - 1]
              })`;
            const bgColor = document.querySelectorAll(".numbg");
            for (let i = 0; i < bgColor.length; i++) {
              bgColor[i].style.background =
                this.classifyBgList[this.checkStyle - 1];
            }
            this.classifyBg = document.querySelector(".active");
            this.classifyBg.style.background =
              this.classifyBgList[this.checkStyle - 1];
          }, 800);
          //分享内容
          var that=this;
          this.shareOption = {
            title: `${this.departmentName}金融管家：${this.nickName}为您服务！`,
            desc: data.summary,
            imgUrl: this.touxiang,
            link: shareUrl + "&t=" + timestamp,
            success: function (res) {
              // 用户点击了分享后执行的回调函数  数据埋点
              let params = {
                employeeId: that.employeeId,
                unionId: that.unionId,
                type: '1',
                targetId: '0'
              }
              shareCallback(params)
            },
          };
          let shareFunction = function () {
            //需在用户可能点击分享按钮前就先调用
            wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
            wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
            wx.onMenuShareQQ(that.shareOption);//分享到qq
            wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
          };
          //加载微信配置
          wxConfig(shareFunction);
        });
      },
      skipToutiao() {
        if (this.msg.url != "") {
          window.location.href = this.msg.url;
        } else {
          this.$router.push("/electronicCard/notice");
        }
      },
      share() {
        this.show = true;
      },
      confirm() {
        const data = {
          title: `${this.departmentName}金融管家：${this.nickName}为您服务！`,
          description: "",
          cover: this.touxiang,
          share_link: this.shareUrl,
        };
        shareToExternalContact(data);
      },
      cancel(content) {
        var input = document.createElement("input"); // 创建input对象
        input.value = content; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success("复制成功！");
        this.show = false;
      },
      editInfo() {
        let isWxWork = /wxwork/i.test(navigator.userAgent);
        if (!isWxWork) {
          return;
        }
        this.$router.push("/electronicCard/personInfo");
      },
      close() {
        this.show = false;
      },
      showPhone() {
        this.showPhoneCode = true;
      },
      skipCaijing() {
        let isWxWork = /wxwork/i.test(navigator.userAgent);
        if (!isWxWork) {
          return;
        }
        this.$router.push("/electronicCard/caijing");
      },
      skipActivity() {
        let isWxWork = /wxwork/i.test(navigator.userAgent);
        if (!isWxWork) {
          return;
        }
        this.$router.push("/electronicCard/activity");
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .van-swipe {
    margin-top: -10px;
  }

  /deep/ .van-swipe_indicators {
    bottom: 0;
  }

  /deep/ .van-tabs__nav--line {
    bottom: 0;
    background: #f8f9fb;
  }

  /deep/ .van-tab {
    font-size: 15px;
  }

  /deep/ .van-tabs__line {
    bottom: 0;
  }

  /*滚动条样式*/
  .par-type ::-webkit-scrollbar {
    display: none;
  }

  @wait: #45c400;
  @over: #c8cad0;
  @finsh: red;

  /deep/ van-swipe__indicators {
    bottom: 0px !important;
  }

  /deep/.van-dropdown-menu__bar {
    height: 20px;
    box-shadow: none;
  }

  /deep/.van-tabbar {
    box-sizing: content-box !important;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  .van-tabbar--fixed {
    /* left: unset !important; */
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  /deep/ .van-empty__image {
    width: 129px !important;
    height: 115px !important;
  }

  /deep/.van-dropdown-menu__bar {
    height: 20px;
    box-shadow: none;
  }

  /deep/.van-tabbar {
    box-sizing: content-box !important;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  .van-tabbar--fixed {
    /* left: unset !important; */
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  /deep/ .van-empty__image {
    width: 129px !important;
    height: 115px !important;
  }

  .fixedBox {
    position: fixed;
    right: 14px;
    bottom: 200px;
  }

  /deep/ .hide-icon .van-radio__icon {
    visibility: hidden;
  }

  /deep/ .hide-icon .van-radio__label {
    width: 126px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // /deep/ .van-swipe {
  //   margin-top: -10px;
  // }

  /deep/ .van-swipe_indicators {
    bottom: 0;
  }

  /deep/ .van-tabs__nav--line {
    bottom: 0;
    background: #f8f9fb;
  }

  .index {
    width: 100%;
    padding: 12px;
    // height: 812px;
    /* margin: 0 auto; */
    /* height: 100%; */
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    background: url("../../assets/index/bg.png");
    background-size: 100% 812px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .headerLeft {
      img {
        width: 71px;
        height: 18px;
      }
    }

    .headerRight {
      height: 15px;
      font-size: 13px;
      color: #4a4a4a;
      line-height: 15px;
      margin-top: 12px;
    }
  }

  .icon {
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #3975c6;
  }

  .detail {
    .intrudction {
      margin-left: 12px;
      margin-bottom: 10px;
      width: 94%;
      min-height: 17px;
      font-size: 12px;
      color: #9b9b9b;
      line-height: 17px;
      margin-top: 10px;
    }
  }

  .toutiao {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 35px;
    line-height: 24px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    img {
      margin-left: 13px;
      margin-right: 15px;
      width: 31px;
      height: 14px;
    }

    span {
      display: inline-block;
      width: 76%;
      height: 24px;
      font-size: 13px;
      color: #4a4a4a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    img {
      width: 94%;
      height: 81px;
    }

    .title {
      height: 21px;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }

    .subtitle {
      height: 17px;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }

    .left,
    .right {
      position: relative;

      .subcontent {
        position: absolute;
        top: 20px;
        left: 10px;
      }
    }
  }

  .product {
    background: #ffffff;
    border-radius: 10px;

    .product_tab {
      width: 100%;
      // overflow: hidden;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      height: 47px;
      line-height: 47px;
      display: inline-block;
      border-radius: 10px 10px 0px 0px;
      background: #e5e5e5;

      .product_list {
        vertical-align: top;
        display: inline-block;
        width: 33.3%;
        text-align: center;

        .product_name {
          font-size: 16px;
          font-weight: 500;
          color: #999999;
        }

        .active {
          width: 100%;
          color: #fff;
          height: 49px;
          line-height: 47px;
          /* background: #58b2e7; */
          border-radius: 6px 6px 0px 0px;

          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            z-index: -1;
            top: 18px;
            left: 0px;
            width: 100%;
            height: 3px;
            background: #eef1f9;
          }
        }
      }
    }

    .product_con {
      width: 95%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;

      .product_con_left {
        width: 60%;

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .content {
          margin-top: 13px;
          font-size: 13px;
          font-weight: 400;
          color: #959595;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .product_con_right {

        /* width: 45%; */
        img {
          width: 107px;
          height: 84px;
          border-radius: 4px;
        }
      }
    }
  }

  .asideTop {
    background: #f8f9fb;
    width: 100%;
    text-align: center;
    overflow: hidden;
    height: 80px;
    border-radius: 10px;

    .BoxGreen {
      float: left;
      width: 33.3%;
      height: 80px;
      // margin-bottom: 10px;
      background: linear-gradient(180deg, #fafff8 0%, #f2ffee 100%);
    }

    .Box {
      float: left;
      width: 33.3%;
      height: 80px;
      margin-bottom: 10px;
      background: linear-gradient(180deg, #fffbfb 0%, #fff1f1 100%);
    }

    .gray {
      margin-top: 12px;
      height: 18px;
      font-size: 10px;
      font-weight: bold;
      color: #4a4a4a;
      line-height: 14px;
    }

    .m16 {
      height: 20px;
      font-size: 16px;
      color: #d90000;
      line-height: 21px;
    }

    .m18 {
      height: 20px;
      font-size: 16px;
      font-weight: 400;
      color: #249900;
      line-height: 21px;
    }

    .m17 {
      height: 14px;
      font-size: 8px;
      color: #d90000;
      font-weight: 400;
    }

    .m19 {
      height: 14px;
      font-size: 8px;
      color: #249900;
    }

    .Box:nth-child(3) {
      border-right: none;
    }
  }

  .bot {
    margin-top: 12px;
    width: 100%;
    text-align: center;
    height: 14px;
    font-size: 10px;
    color: #9b9b9b;
    line-height: 14px;
  }

  .bottom {
    background: linear-gradient(180deg, #fffcf6 0%, #fff8ec 100%);
    height: 76px;
    // margin-top: 12px;
    overflow: hidden;
    border-radius: 10px;

    .con {
      float: left;
      margin-top: 10px;
      width: 25%;
      text-align: center;

      .a12 {
        height: 14px;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
        color: #4a4a4a;
      }

      .a13 {
        height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #d90000;
        line-height: 21px;
        margin-top: 4px;
      }

      .a14 {
        font-size: 9px;
        font-weight: 400;
        color: #9b9b9b;
      }
    }
  }

  .last {
    position: fixed;
    bottom: 0;
    left: 2%;
    width: 96%;
    display: flex;
    justify-content: space-between;
    margin: 22px 0px;

    .btn1 {
      width: 45%;
      height: 42px;
      background: #ffffff;
      border-radius: 5px;
      border: 1px solid #3974c6;
      font-size: 14px;
      font-weight: bold;
      color: #3974c6;
      line-height: 20px;
    }

    .btn2 {
      width: 45%;
      height: 42px;
      background: #3974c6;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 20px;
      border: none;
    }
  }

  .address {
    margin: 17px 0 16px 20px;
    height: 21px;
    font-size: 15px;
    color: #333333;
    line-height: 21px;
  }

  .url {
    margin: 0 0 20px 20px;
    font-size: 13px;
    color: #999999;
    line-height: 19px;
  }

  .close {
    font-size: 16px;
    float: right;
    margin-right: 14px;
    margin-top: -4px;
  }

  .phoneT {
    text-align: center;
    margin: 38px;
    height: 35px;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }

  .allUserInfo {
    /* margin-top: 12px; */
    width: 100%;
  }

  .userInfo {
    width: 100%;
    background: url("../../assets/index/infoBg.png") no-repeat;
    background-size: 100% 100%;
    height: 207px;

    .content {
      width: 100%;
      height: 207px;
      display: flex;

      .contentLeft {
        margin: 36px 90px 35px 36px;
        width: 78px;
        height: 134px;

        .avatar {
          width: 78px;
          height: 78px;
          border-radius: 50%;
        }

        .contentBot {
          width: 78px;
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
        }

        .round {
          width: 32px;
          height: 32px;
          background: #ffffff;
          text-align: center;
          border-radius: 50%;
          line-height: 32px;
        }

        .phone {
          width: 12px;
          height: 12px;
        }
      }

      .contentRight {
        .logo {
          margin: 22px 0 40px 0;
          width: 138px;
          height: 28px;
        }

        .name {
          .name2 {
            height: 24px;
            line-height: 22px;
            display: inline-block;
            overflow: hidden;
          }

          .span {
            display: inline-block;
            padding: 0 8px 8px 9px;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }

          .span2 {
            width: 134px;
            animation: marquee-content 10s infinite linear;
            display: inline-block;
            padding: 0 8px 10px 9px;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            white-space: nowrap;
          }

          @keyframes marquee-content {

            0%,
            30% {
              transform: translateX(0);
            }

            70%,
            100% {
              transform: translateX(-160%);
            }
          }

          .nameImg {
            margin-bottom: 6px;
            margin-left: 4px;
            width: 12px;
            height: 12px;
          }
        }

        .position {
          padding-left: 9px;
          font-size: 12px;
          color: #4a4a4a;
        }
      }
    }
  }

  .expand {
    width: 100px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: #4a4a4a;
    margin-top: 14px;
    margin-bottom: 14px;

    img {
      width: 9px;
      height: 5px;
    }
  }

  .detailbg {
    // background: url("../../assets/index/expandBg.png") no-repeat;
    padding: 2px 0 12px 0;
    background-size: 100% 100%;
    background: rgb(253, 253, 253, 0.6);
  }

  .infoNull {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .infoTitle {
      padding-top: 75px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #9b9b9b;
      margin: 0 auto;
    }

    .infoBtn {
      width: 79px;
      padding: 4px 20px;
      background: #3974c6;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 15px;
    }
  }

  .mornEven {
    // margin-top: 10px;
    width: 100%;
    height: 135px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    /*解决ios上滑动不流畅*/
    -webkit-overflow-scrolling: touch;

    .morning {
      display: inline-block;
      margin-right: 10px;
      width: 237px;
      height: 135px;
      background: url("../../assets/index/morning.png") no-repeat;
      background-size: 237px 100%;
    }

    .evening {
      display: inline-block;
      margin-right: 10px;
      width: 237px;
      height: 135px;
      background: url("../../assets/index/evening.png") no-repeat;
      background-size: 237px 100%;
    }

    .morning_top {
      font-size: 11px;
      color: #ffffff;
      margin-top: 6px;
      margin-bottom: 40px;
    }

    .morning_title {
      width: 220px;
      height: 40px;
      margin-bottom: 6px;
      font-size: 15px;
      color: #ffffff;
      white-space: pre-wrap;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 超出几行省略 */
      overflow: hidden;
    }

    .shareTimes {
      font-size: 9px;
      color: #ffffff;
    }
  }

  // 热销产品专区
  .prefecture {
    width: 100%;
    height: 651px;
    margin: 0 auto;
    margin-top: 12px;
    background-color: #fff;
    overflow: hidden;

    .pic {
      // width: 100%;
      margin: 24px 12px 12px 12px;

      .prefectureImg {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        height: 580px;
      }

      .prefectureName {
        margin-top: 20px;
        font-size: 12px;
        color: #4a4a4a;
      }
    }
  }

  // 聚合接口数据
  .juhe {
    height: 220px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    .juhe_top {
      width: 94%;
      margin: 0 auto;
      margin-bottom: 10px;
      margin-top: 10px;
      height: 24px;

      span {
        display: inline-block;
        margin-right: 8px;
        width: 9px;
        height: 9px;
        /* background: #58b2e7; */
        border-radius: 1px;
      }

      i {
        font-style: normal;
        height: 23px;
        font-size: 16px;
        font-weight: bold;
        color: #262626;
      }
    }

    .aside {
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .title {
        width: 221px;
        height: 56px;
        font-size: 17px;
        font-weight: bold;
        color: #262626;
        line-height: 28px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 超出几行省略 */
        overflow: hidden;
      }

      .title2 {
        width: 100%;
        height: 56px;
        font-size: 17px;
        font-weight: bold;
        color: #262626;
        line-height: 28px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 超出几行省略 */
        overflow: hidden;
      }
    }

    .main {
      width: 94%;
      height: 74px;
      margin: 0 auto;
      margin-top: 8px;
      font-size: 13px;
      color: #717174;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      /* 超出几行省略 */
      overflow: hidden;
    }

    .bott {
      width: 94%;
      margin: 0 auto;
      margin-top: 10px;
      height: 19px;
      font-size: 13px;
      color: #a5a5a5;
    }
  }

  // 日思录
  .risilu {
    width: 100%;
    min-height: 268px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    overflow: hidden;

    .risilu_content {
      width: 90%;
      min-height: 241px;
      margin: 0 auto;
      background: url("../../assets/index/risilu.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 8px;
      overflow: hidden;

      .title {
        margin-top: 54px;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #5f5044;
        text-align: center;
      }

      .con {
        width: 261px;
        height: 114px;
        margin: 0 auto;
        font-size: 13px;
        color: #5f5044;
        line-height: 19px;
      }
    }
  }

  .topic {
    width: 100%;
    min-height: 134px;
    margin: 0 auto;
    // background: #fff;
    border-radius: 6px;

    .topicTop {
      width: 96%;
      margin: 0 auto;
      height: 43px;
      line-height: 43px;
      display: flex;
      justify-content: space-between;

      .left {
        font-size: 16px;
        color: #262626;
        font-weight: bold;
      }

      .right {
        font-size: 12px;
        color: #717174;
      }
    }

    .topicMain {
      width: 100%;
      display: flex;
      background: #ffffff;
      min-height: 111px;
      border-radius: 10px;

      .content {
        width: 93%;
        margin: 0 auto;
        min-height: 111px;
        background: #ffffff;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
      }

      .left {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 16px;
        width: 68%;

        .title {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tag {
          min-height: 14px;

          span {
            display: inline-block;
            padding: 4px;
            height: 18px;
            line-height: 10px;
            background: #eef1f9;
            border-radius: 2px;
            font-size: 10px;
            color: #7cadfe;
            margin-right: 4px;
          }
        }

        .shareData {
          font-size: 11px;
          color: #a5a5a5;
        }
      }

      .right {
        width: 23%;
        margin-top: 10px;

        img {
          border-radius: 4px;
          width: 98%;
        }
      }
    }
  }

  .m20 {
    width: 96%;
    margin: 0 auto;
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: space-between;

    .left {
      font-size: 16px;
      color: #262626;
      font-weight: bold;
    }
  }

  .selectAct {
    .actTop {
      height: 42px;
      line-height: 42px;
      display: flex;
      justify-content: space-between;

      .left {
        font-size: 16px;
        font-weight: bold;
        color: #262626;
      }

      .right {
        font-size: 12px;
        color: #717174;
      }
    }

    img {
      width: 100%;
      height: 131px;
      border-radius: 6px;
    }
  }

  .home_box {
    position: fixed;
    bottom: 255px;
    right: 12px;
    display: flex;
    flex-direction: column;

    img {
      width: 48px;
      height: 54px;
    }

    span {
      font-size: 9px;
      font-weight: 400;
      color: #3975c6;
      text-align: center;
    }
  }

  .home_qiwei {
    position: fixed;
    bottom: 195px;
    right: 12px;
    display: flex;
    flex-direction: column;

    img {
      width: 48px;
      height: 54px;
    }

    span {
      font-size: 9px;
      font-weight: 400;
      color: #3975c6;
      text-align: center;
    }
  }

  .home_robot {
    position: fixed;
    bottom: 135px;
    right: 12px;
    display: flex;
    flex-direction: column;

    img {
      width: 48px;
      height: 54px;
    }
  }

  .entry-list {
    /* width: 100%;
position: relative;
overflow: hidden; */

    .icon-box {
      width: 100%;
      white-space: nowrap;
      /* height: 91px; */
      overflow-x: scroll;
      overflow-y: hidden;
      /*解决ios上滑动不流畅*/
      -webkit-overflow-scrolling: touch;
      display: flex;
      flex-direction: row;

      /* width: 100%;
position: absolute;
left: 0;
top: 0;
right: -17px;
bottom: 0;
display: inline-flex;
flex-direction: row;
overflow-x: scroll;

overflow-y: hidden; */
      .entry-item {
        position: relative;
        margin: 0 10px;
        width: 150px;
        text-align: center;
        margin-bottom: 10px;
        cursor: pointer;

        .img-view {
          display: inline-block;
          position: relative;

          img {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-bottom: 4px;
          }
        }

        .entry-name {
          line-height: 18px;
          font-weight: 400;
          color: #4e5565;
        }
      }
    }
  }

  .fs12 {
    font-size: 12px;
  }

  .inner-container::-webkit-scrollbar {
    display: none;
  }

  .outer-container {
    width: 100%;

    height: 91px;

    position: relative;

    overflow: hidden;
  }

  .inner-container {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: -17px;
    bottom: 0;
    display: inline-flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;

    .entry-item {
      margin: 0 20px;
    }
  }

  .classifyBtn {
    /* margin: 20px 5vw; */
    margin-top: 6px;
    padding: 10px 0px;
    /* border: 1px solid crimson; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
  }

  /****** 分类导航******/
  .classify-box {
    width: 90vw;
    /* height: 200px; */
    /* padding: 10px 0px; */
    /* margin: 20px 5vw; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    overflow-x: scroll;
    white-space: nowrap;
    /*不换行*/
  }

  .classify-box::-webkit-scrollbar {
    display: none;
  }

  .classify-items {
    min-width: 20%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }

  .item {
    /* width: 80px; */
    /* height: 50%; */
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .itempic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #58b2e7;
    /* padding-bottom: 100%; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itempic img {
    width: 20px;
    height: 20px;
    position: absolute;
  }

  .itemText {
    margin-top: 10px;
    line-height: 18px;
    font-weight: 400;
    color: #4e5565;
    font-size: 12px;
  }

  /* 分页器 */
  .classify-bar {
    margin-top: 10px;
    width: 90px;
    height: 4px;
  }

  .classify-bar .bar-track {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #d0d3da;
    overflow: hidden;
  }

  .classify-bar .bar-thumb {
    height: 100%;
    border-radius: 4px;
    background: #3974c6;
  }

  .copyright {
    width: 100%;
    padding: 10px;
    background: #f8f9fb;
    margin: 10px;
    margin: 0 auto;
    border-radius: 6px;
    margin-top: 12px;

    .box_title {
      color: #acadb1;
      font-size: 12px;
    }

    .box_cen {
      margin-top: 5px;
      color: #acadb1;
      font-size: 12px;
      line-height: 20px;
    }
  }
</style>